html {
  height: -webkit-fill-available;
}

body {
  background-color: #fff;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
}
