/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  80% {
    -webkit-transform: translateX(3px);
              transform: translateX(3px);
  }
  90% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
}

@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  80% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}  